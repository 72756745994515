import * as React from 'react';
import restProvider from 'ra-data-json-server';
import {
  Admin,
  Resource,
  CustomRoutes,
  defaultTheme, defaultDarkTheme,
} from 'react-admin';
import AppList from './components/apps/AppList';
import AppCreate from './components/apps/AppCreate';
import AppEdit from './components/apps/AppEdit';
import { Route } from 'react-router-dom';
import { MyLayout } from './layout/Layout';
import AccountList from './components/account/AccountList';
import AccountCreate from './components/account/AccountCreate';
import AccountEdit from './components/account/AccountEdit';
import UsersList from './components/users/UsersList';
import GroupCreate from './components/group/GroupCreate';
import GroupEdit from './components/group/GroupEdit';
import GroupList from './components/group/GroupList';
import BotMessage from './components/apps/BotMessage';
import authProvider from './AuthProvider';
import AppsRating from './components/apps/AppsRating';
import BotAppsList from './components/apps/BotAppsList';
import InstallCalculator from './components/instalСalculator/InstallCalculator';
import NamingsList from './components/namings/NamingsList';
import PixelsList from './components/pixels/PixelsList';
import ShareLogList from './components/shareLog/ShareLog';
import AnalyzerSettingsEdit from './components/analyzerBot /AnalyzerSettingsEdit';
import KeitaroCampaignEdit from './components/keitaroCampaigns/KeitaroCampaignEdit';
import KeitaroCampaignCreate from './components/keitaroCampaigns/KeitaroCampaignCreate';
import KeitaroCampaignsList from './components/keitaroCampaigns/KeitaroCampaignsList';

export const API_URL = 'https://mirapanelapps.com/banda-bot/backend';
// export const API_URL = `${process.env.REACT_APP_API_URL}`;

const App = () => {
  return (
    <Admin
      layout={MyLayout}
      authProvider={authProvider}
      dataProvider={restProvider(API_URL)}
      theme={defaultTheme}
      darkTheme={defaultDarkTheme}
    >
      <Resource
        name='apps'
        list={AppList}
        create={AppCreate}
        edit={AppEdit}
      />
      <Resource
        name='botApps'
        create={AppCreate}
        list={BotAppsList}
        edit={AppEdit}
      />
      <Resource
        name='accounts'
        list={AccountList}
        create={AccountCreate}
        edit={AccountEdit}
      />
      <Resource
        name='users'
        list={UsersList}
      />
      <Resource
        name='groups'
        list={GroupList}
        create={GroupCreate}
        edit={GroupEdit}
      />
      <Resource
        name='namings'
        list={NamingsList}
      />
      <Resource
        name='pixels'
        list={PixelsList}
      />
      <Resource
        name='share-log'
        list={ShareLogList}
      />
      <Resource
        name='analyzer-settings'
        edit={AnalyzerSettingsEdit}
      />
      <Resource
        name='keitaro-campaigns'
        edit={KeitaroCampaignEdit}
        create={KeitaroCampaignCreate}
        list={KeitaroCampaignsList}
      />
      <CustomRoutes>
        <Route path='/app-rating' element={<AppsRating />} />
      </CustomRoutes>
      <CustomRoutes>
        <Route path='/install-calculator' element={<InstallCalculator />} />
      </CustomRoutes>
      <CustomRoutes>
        <Route path='/bot_messages' element={<BotMessage />} />
      </CustomRoutes>
    </Admin>
  );
};

export default App;
