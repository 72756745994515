import React from 'react';
import {
  AutocompleteArrayInput,
  Edit,
  SaveButton,
  SimpleForm,
  TextInput,
  Toolbar,
  useNotify,
  useRedirect,
} from 'react-admin';
import { closedGeos } from '../../data/closedGeos';
import { Typography } from '@material-ui/core';

const SettingsSaveButton = () => {
  const notify = useNotify();
  const redirect = useRedirect();
  const onSuccess = () => {
    notify(`Settings saved!`);
    redirect('/keitaro-campaigns');
  };
  return (
    <SaveButton type='button' mutationOptions={{ onSuccess }} />
  );
};

const SettingEditToolbar = () => (
  <Toolbar>
    <SettingsSaveButton />
  </Toolbar>
);

const AnalyzerSettingsEdit = (props: any) => {
  return (
    <Edit
      title='Edit Settings'
      mutationMode='pessimistic'
      {...props}
    >
      <SimpleForm toolbar={<SettingEditToolbar />}>
        <Typography variant="h6">Ignore list</Typography>
        <TextInput source='sub_id_1' resettable fullWidth />
        <TextInput source='destination' resettable fullWidth />
        <AutocompleteArrayInput
          source='geo'
          choices={closedGeos}
          fullWidth
        />
      </SimpleForm>
    </Edit>
  );
};

export default AnalyzerSettingsEdit;
