import React from 'react'
import { BooleanInput, Create, NumberInput, SelectInput, SimpleForm, TextInput } from 'react-admin';

const KeitaroCampaignCreate = (props: any) => {
  return (
    <Create title='Create a Campaign' {...props}>
      <SimpleForm>
        <NumberInput source='keitaroId' />
        <TextInput source='name' />
        {/*<SelectInput*/}
        {/*  source='fetchInterval'*/}
        {/*  choices={[*/}
        {/*    { id: 'Android', name: 'Android' },*/}
        {/*    { id: 'iOS', name: 'iOS' }*/}
        {/*  ]}*/}
        {/*/>*/}
        {/*<SelectInput source='statRange' />*/}
        <BooleanInput source='ignore' />
      </SimpleForm>
    </Create>
  )
}

export default KeitaroCampaignCreate
