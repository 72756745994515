import React from 'react'
import { BooleanInput, Edit, NumberInput, SelectInput, SimpleForm, TextInput } from 'react-admin';

const KeitaroCampaignEdit = (props: any) => {
  return (
    <Edit
      title='Edit Campaign'
      mutationMode='pessimistic'
      {...props}
    >
      <SimpleForm>
        <NumberInput source='keitaroId' />
        <TextInput source='name' sx={{ width: '400px' }} />
        {/*<SelectInput source='fetchInterval' />*/}
        {/*<SelectInput source='statRange' />*/}
        <BooleanInput source='ignore' />
      </SimpleForm>
    </Edit>
  )
}

export default KeitaroCampaignEdit;
